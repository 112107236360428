<template>
	<div class="update-user">
		<van-form @submit="onSubmit">
			<van-field v-model="userInfo.nickname" name="nickname" label="用户名" placeholder="用户名"
				:rules="[{ required: true, message: '请填写用户名' }]" />
				<van-field v-model="userInfo.tel" name="tel" label="手机号" placeholder="手机号"
					:rules="[{ required: true, message: '请填写手机号' }]" readonly />
			<van-field name="uploader" label="头像">
				<template #input>
					<van-uploader v-model="userHead" :after-read="afterRead" max-count="1" />
				</template>
			</van-field>
			<div style="margin: 16px;">
				<button @click="submit" style="width: 100%; height: 37px;font-size: 16px;background: #FC6228;border: none;color: #FFFFFF;border-radius: 18px;">
				  提交
				</button>
			</div>
		</van-form>
	</div>
</template>

<script>
	import {
		reactive
	} from 'vue';
	import {
		ref
	} from 'vue';
	import {
		uploadImage,
		updateUser,
		info
	} from '../../api/user.js'

	export default {
		setup() {
			const state = reactive({
				username: '',
				password: '',
				tel:''
			});
			const value = ref([

			]);

			return {
				state,
				value,
			};
		},
		data() {
			return {
				avatar: '',
				userInfo: '',
				userHead: [{
					url: ''
				}],
				imageUrl: this.url.imageUrl,
			}
		},
		mounted() {

			info().then((res) => {
				console.log(res)
				this.userInfo = res.data.info
				console.log(res.data.info.avatar)
				this.userHead[0].url =  res.data.info.avatar
				this.avatar = res.data.info.avatar

			})
		},
		methods: {
			submit(value) {
				console.log(value)
			},
			onSubmit(values) {
				console.log(this.imageUrl)
				var that = this
				let params = {}
				params.avatar = this.avatar
				params.nickname = values.nickname
				if (params.avatar) {
					updateUser(params).then(res => {
						if (res.code == 200) {
							that.$toast('修改成功')
							setTimeout(function() {
								that.$router.go(-1)
							}, 1000)
						}
					})
				} else {
					that.$toast('请选择头像')
				}
			},
			afterRead(file) {
				console.log(file)
				file.status = 'uploading';
				file.message = '上传中...';
				let formData = new FormData()
				formData.append('file', file.file)
				// formData.append('tab_id', 0)
				// formData.append('type', 'image')
				uploadImage(formData).then(res => {
					console.log(res)
					if (res.code == 200) {
						file.status = 'done ';
						file.message = '上传完成';
						this.avatar = this.imageUrl+res.data.path
						console.log(this.avatar)
					} else {
						file.status = 'failed';
						file.message = '上传失败';
					}
				})


			}
		}
	};
</script>

<style lang="less" scoped>
	.update-user {
		padding: 15px;
		background-color: #FFFFFF;
		height: 100%;
	}
</style>
